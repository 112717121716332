import { effect, inject, Injectable, signal } from '@angular/core';
import { LOCALSTORAGE } from '../core/services/localstorage.token';

@Injectable({
  providedIn: 'root',
})
export class InvitationState {
  #ls = inject(LOCALSTORAGE);

  inviteToken = signal<string | null>(this.#ls.getItemParsed<string>('inviteToken', true) ?? null);

  tokenEffect = effect(() => {
    const token = this.inviteToken();

    if (token) {
      this.#ls.setItemParsed('inviteToken', token, true);
    } else {
      this.#ls.removeItem('inviteToken', true);
    }
  });
}
