import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { SparkleButtonComponent, SparkleProgressBarComponent } from '@sparkle-ui/core';
import { catchError, finalize, throwError } from 'rxjs';
import { AuthState } from '../auth/auth.state';
import CustomCardComponent from '../core/components/custom-card/custom-card.component';
import { InvitationDetails, SecurityService } from '../core/openapi';
import { LayoutState } from '../layout/layout.state';
import { InvitationState } from './invitation.state';

export const inviteTokenGuard: CanActivateFn = (route) => {
  const state = inject(InvitationState);
  const token = route.queryParams['token'];

  if (token) {
    state.inviteToken.set(token);
  }

  return true;
};

export const awaitingInviteAcceptGuard: CanActivateFn = (route) => {
  const state = inject(InvitationState);
  const router = inject(Router);

  if (state.inviteToken()) {
    return router.createUrlTree(['/accept-invitation']);
  }

  return true;
};

@Component({
  selector: 'app-invitation',
  imports: [CustomCardComponent, SparkleButtonComponent, SparkleProgressBarComponent],
  templateUrl: './invitation.component.html',
  styleUrl: './invitation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class InvitationComponent {
  #layoutState = inject(LayoutState);
  #router = inject(Router);
  #securityService = inject(SecurityService);
  #invitationState = inject(InvitationState);
  #authState = inject(AuthState);

  activeUser = this.#authState.$activeUser;
  username = this.#authState.$userName;
  emailUnlessUsernameIsEmail = computed(() => {
    const user = this.#authState.$activeUser();
    return (user?.name || user?.lastName) && user?.email ? ' (' + user?.email + ')' : '';
  });

  isDarkMode = this.#layoutState.isDarkMode$;
  isAccepting = signal(false);
  isLoading = signal(true);
  invalidToken = signal(false);
  invitationDetails = toSignal<InvitationDetails>(
    this.#securityService
      .postInvitationsByToken({
        token: this.#invitationState.inviteToken()!,
      })
      .pipe(
        catchError((err) => {
          if (err.error.status === 404) {
            this.invalidToken.set(true);
          }

          return throwError(() => err);
        }),
        finalize(() => {
          this.isLoading.set(false);
          const loggedIn = this.#authState.$isLoggedIn();

          if (!loggedIn) {
            return this.#router.navigate(['/auth']);
          }
        })
      )
  );

  logout() {
    this.#authState.logout();
  }

  invalidTokenRedirect() {
    this.#invitationState.inviteToken.set(null);
    this.#authState.logout();
  }

  acceptInvitation() {
    this.isAccepting.set(true);

    this.#securityService
      .postInvitationsByTokenAccept({
        token: this.#invitationState.inviteToken()!,
      })
      .pipe(
        finalize(() => {
          this.isAccepting.set(false);
          this.#invitationState.inviteToken.set(null);
        })
      )
      .subscribe({
        next: () => {
          this.#router.navigate(['/app']);
        },
      });
  }
}
