<app-custom-card>
  @if (isLoading()) {
    <h3 i18n>Loading invitation details...</h3>

    <spk-progress-bar class="indeterminate primary" />
  } @else if (invalidToken()) {
    <h3 i18n>Invalid invitation link</h3>

    <button spk-button class="raised primary" (click)="invalidTokenRedirect()" i18n>Logout</button>
  } @else {
    <div class="message">
      <h3 i18n>
        {{ invitationDetails()?.organizationName }}; Have invited you to join their organisation on
        <a href="https://duplicati.com" target="_blank">duplicati.</a>
      </h3>

      <p i18n>
        You're currently logged in as
        <strong>{{ username() }}{{ emailUnlessUsernameIsEmail() }}</strong>

        . If you want to connect with a different account, please logout and login with the new account.

        <button spk-button class="outlined small" (click)="logout()">Logout</button>
      </p>
    </div>

    <button
      spk-button
      class="raised primary"
      [class.loading]="isAccepting()"
      (click)="acceptInvitation()"
      [disabled]="isAccepting()"
      i18n>
      Accept invitation
    </button>
  }
</app-custom-card>
